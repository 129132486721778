import texts from "~/texts.config";

export async function getText(key: string) {
    const config = useRuntimeConfig();

    try {
        if (texts[key][config.public.isDomino ? 'domino' : 'a4']) {
            return texts[key][config.public.isDomino ? 'domino' : 'a4'];
        }
    } catch (error) {
        //
    }

    return '';
}