<script setup>
import { useCartStore } from "~/stores/cart";

const props = defineProps(['withText']);

const { $modalsBus } = useNuxtApp();
const cartStore = useCartStore();

const click = () => {
    $modalsBus.$emit('sidebars.cart:open');
    useMetrika().goal("MAIN_PAGE_CART_WIDGET_CLICK");
}

</script>
<template>
    <button class="cart-button" @click="click">
        <div class="cart-button__inner">
            <div v-if="withText" class="text-container">
                Корзина
            </div>
            <div class="icon-container">
                <div class="cart-icon">
                    <UiIconsBasketIcon />
                </div>
            </div>
        </div>
    </button>
</template>

<style lang="scss" scoped>
a {
    text-decoration: none;
}

.cart-button {
    margin: 8px 0;
    border: none;
    box-shadow: none;
    outline: none;
    background: none;
}

.cart-button__inner {
    padding: 10px;
    position: relative;
    background-color: #fff;
    border-radius: 50px;

    // width: 130px;
    display: flex;
    flex-direction: row;

    cursor: pointer;

    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    transition: all 0.3s;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    border: 1px solid #eee;

    @if not $is-domino {
        box-shadow: none;
        border: 1px solid #000;
    }

    @media screen and (min-width: 760px) {
        border: none;
        background-color: rgb(255 255 255 / 80%);

        @if not $is-domino {
            border: 1px solid #000;
        }
    }

    &:hover {
        transition: all 0.3s;

        .icon-container {
            opacity: 0.8;
            transition: all 0.3s;
        }
    }

    &-count {
        margin-left: 10px;
        margin-top: auto;
        margin-bottom: auto;
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    &-divider {
        display: block;
        width: 1px;
        background-color: #E3E3E3;
        margin: 0 17px;
    }

    .cart-error-popover {
        position: absolute;
        top: calc(100% + 10px);
        right: 0px;
        width: 240px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 12px;
        background: #fff;
        padding: 4px 10px;
        color: #3d3d3d;
        text-align: center;
        filter: drop-shadow(0px 0px 1px #B6B6B6);
        z-index: 10;

        @media screen and (max-width: 767px) {
            right: 50%;
            top: -34px;
            bottom: auto;
            width: calc(100vw - 200px);
            transform: translateX(50%);

            .cart-error-text {
                display: none;
            }
        }

        &:before {
            content: "";
            position: absolute;
            top: -5px;
            right: 20%;
            height: 5px;
            width: 14px;
            background: inherit;
            clip-path: polygon(50% 0, 100% 100%, 0 100%);

            @media screen and (max-width: 767px) {
                top: 100%;
                right: 50%;
                clip-path: polygon(0 0, 100% 0, 50% 100%);
            }
        }

        .cart-error-title {}

        .cart-error-text {
            color: #3d3d3d;
            font-weight: 400;
            font-size: 12px;
        }
    }

    .text-container {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;

        margin-top: auto;
        margin-bottom: auto;
        margin-right: 10px;
        margin-left: 8px;
    }

    .icon-container {
        position: relative;

        .cart-icon {
            display: flex;
            height: 100%;
        }

        .icon {
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 28px;
            overflow: hidden;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

            @if not $is-domino {
                box-shadow: none;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }


    }

    i {
        position: relative;
        top: -0.5px;
        line-height: 0;
        font-size: 14px;
        vertical-align: middle;
        color: #fff;
        text-decoration: none;
    }

    span {
        padding-right: 10px;
        margin-top: auto;
        margin-bottom: auto;
        text-decoration: none;
        color: #000;
    }
}
</style>