import { defineStore } from 'pinia';

export const useActiveOrdersStore = defineStore('activeOrders', {

    state: () => ({
        activeOrders: [] as any[]
    }),

    getters: {},

    actions: {
        async fetch() {
            try {
                const data = await useDFetch("orders/active");
                this.activeOrders = data as any[];
            } catch (error) {

            }
        }
    },
});