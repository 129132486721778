<script setup>
const nuxtApp = useNuxtApp();

const props = defineProps(['modalId']);

const loading = ref(false);
const slotRef = ref(null);

const clientHydrating = process.client && !nuxtApp.isHydrating;


nuxtApp.hook("page:start", () => {
    loading.value = true;
});
nuxtApp.hook("page:finish", () => {
    loading.value = false;

    if (process.client) {
        if (clientHydrating) {
            setTimeout(() => {
                if (slotRef.value) {
                    slotRef.value.classList.add("show");
                }

            }, 50);
        }
    }
});

nuxtApp.$modalsBus.$on(`modals.${props.modalId}:close`, () => {
    close();
});

const close = () => {
    useRouter().replace('/');
}

</script>

<template>
    <DModal @close="close" class="address-d-modal">

        <div v-if="loading" class="d-modal-loader">
            <div class="spinner-border text-white" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div :class="{ show: !clientHydrating }" class="d-modal-anim" ref="slotRef">
            <slot />
        </div>
    </DModal>
</template>

<style lang="scss">
.address-d-modal {
    .d-modal {
        .d-modal-content {
            max-width: 600px;

        }
    }

}
</style>
