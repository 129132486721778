<script setup>
const { $bootstrap } = useNuxtApp()

const props = defineProps({
    backdrop: {
        default: true
    }
});

var modal = null;

onMounted(() => {
    const router = useRouter()
    var modalEl = document.getElementById('cart-modal')
    modal = new $bootstrap.Modal(modalEl, {})
    modalEl.addEventListener('hidden.bs.modal', function (event) {
        router.replace('/')
    })
    modal.show();
})

onBeforeUnmount(() => {
    modal.hide();
})

</script>
<template>
    <div class="modal fade" id="cart-modal" :data-bs-backdrop="backdrop ?? true" tabindex="-1"
        aria-labelledby="cart-modal-label" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.modal {
    .modal-content {
        background-color: transparent;
        border: none;
    }
}
</style>