<script setup>
const nuxtApp = useNuxtApp();

const props = defineProps(['modalId']);

const loading = ref(false);
const slotRef = ref(null)

const clientHydrating = process.client && !nuxtApp.isHydrating;


nuxtApp.hook("page:start", () => {
    loading.value = true;
});
nuxtApp.hook("page:finish", () => {
    loading.value = false;

    if (process.client) {
        if (clientHydrating) {
            setTimeout(() => {
                if (slotRef.value != null) {
                    slotRef.value.classList.add("show")
                }

            }, 50);
        }
    }
});

nuxtApp.$modalsBus.$on(`modals.${props.modalId}:close`, () => {
    close();
});

const close = () => {
    useRouter().replace('/')
}

</script>
<template>
    <DModal @close="close" class="auth-d-modal">
        <slot />
    </DModal>
</template>

<style lang="scss">
.auth-d-modal {
    .d-modal {
        .d-modal-content {

            max-width: 460px;
        }
    }

}
</style>