<script setup>
import animateScrollTo from 'animated-scroll-to';
import { useActive } from 'vue-use-active-scroll';
import { useAuthStore } from '~/stores/auth';
import { useCartStore } from '~/stores/cart';

const props = defineProps(['items']);

const authStore = useAuthStore();
const cartStore = useCartStore();

const links = ref([
    ...props.items.map((item) => { return { href: `${item.slug}`, label: item.name }; })
]);

const targets = computed(() => links.value.map(({ href }) => href));

const { isActive, setActive, activeId } = useActive(targets, {
    overlayHeight: 60,
    edgeOffset: {
        first: 0,
        last: -100,
    },
});

const scrollToTab = useDebounce((newId) => {

    if (!newId) {
        return;
    }

    const scrollContainer = document.getElementsByClassName('categories-list')[0];

    const element = document.getElementById(`tab-${newId}`);
    const centerPos = element.offsetLeft + element.offsetWidth / 2 - scrollContainer.offsetWidth / 2;


    scrollContainer.scrollTo({ left: centerPos, behavior: 'smooth' });

    return;
    document.getElementById(newId.replace("cat", "tab")).scrollIntoView({
        behavior: 'smooth',
        inline: 'center'
    });

}, 200);

const onTabClick = (item) => {
    // setActive(`tab-${item.slug}`);

    animateScrollTo(document.getElementById(`${item.slug}`), {
        minDuration: 300,
        maxDuration: 600,
        verticalOffset: -60
    });
};

watch(activeId, scrollToTab);

const stickyHeader = ref(null);
const sticking = ref(false);
// if (process.client) {
//     onMounted(() => {
//         window.onscroll = function () {
//             var rect = stickyHeader.value.getBoundingClientRect();
//             console.log(rect.top)
//             if (rect.top < 20) {
//                 sticking.value = true;
//             } else {
//                 sticking.value = false;
//             }
//         };
//     });
// }



</script>

<template>
    <div class="sticky-categories" ref="stickyHeader" :class="{ sticking }">

        <div class="categories-container">
            <div class="container-lg p-0">
                <div class="categories-internal-container">
                    <div class="d-flex flex-row">
                        <div class="categories-list">

                            <a v-for="item in items" @click.native="onTabClick(item)" :id="`tab-${item.slug}`"
                                :href="`#${item.slug}`" class="category-item"
                                :class="{ active: isActive(`${item.slug}`), accent: item.slug == 'sushi' }">
                                {{ item.name }}
                            </a>

                        </div>
                        <div class="ms-auto d-none d-md-block">
                            <div v-if="!cartStore.firstLoading">
                                <CatalogCartButton v-if="!cartStore.isEmpty" />
                                <CatalogCartIconButton v-else :withText="true" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.sticky-categories {
    position: sticky;
    top: if($is-domino, 0, 10px);
    z-index: 9;
    margin-top: 20px;
    margin-left: 10px;

    @if not $is-domino {
        margin-right: 10px;
    }

    // overflow: hidden;

    &.sticking {}
}

.categories-container {
    width: 100%;
    z-index: 9;
    background-color: #fafafac2;
    backdrop-filter: blur(5px);
    // border: 1px solid #000;
    margin-bottom: 30px;
    // overflow: hidden;

    @if not $is-domino {
        background-color: transparent;
        backdrop-filter: none;
    }

    .categories-internal-container {
        @if not $is-domino {
            background-color: #FAFAFA;
            border-radius: 60px;
            border: 1px solid #000;
        }
    }
}

.categories-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-wrap: nowrap;
    overflow: scroll;

    padding-top: 10px;
    padding-bottom: 9px;
    padding-left: 10px;
    padding-right: 10px;

    gap: 10px;

    @media screen and (min-width: 768px) {
        // padding-left: 130px;
        // padding-right: 20px;
    }


    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
        box-shadow: none;
        opacity: 0;
    }

    .category-item {
        display: flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: auto 0;

        background-color: transparent;
        border-radius: 50px;
        box-shadow: none;
        cursor: pointer;

        color: #000;

        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        text-decoration: none;
        line-height: 1;
        text-wrap: nowrap;

        &.active {
            background-color: if($is-domino, #fff, $primary);
            color: if($is-domino, $primary, #000);
            border: if($is-domino, none, 1px solid #000);
            font-weight: 600;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03);

            @if not $is-domino {
                box-shadow: none;
            }
        }

        &.accent {
            background-color: #DD052B;
            color: #000;

            &.active {
                background-color: #DD052B;
                color: #fff;
                font-weight: 600;
                box-shadow: none;
            }
        }
    }
}
</style>