<script setup>
const props = defineProps(['imageUrl', 'imageSet', 'name']);

const srcSet = {};

if (props.imageSet) {
    props.imageSet.forEach(el => {
        if (!srcSet[el.ext]) {
            srcSet[el.ext] = [
                el.url + ' ' + el.size + 'w'
            ];
        } else {
            srcSet[el.ext].push(el.url + ' ' + el.size + 'w');
        }
    });
}

const srcStrings = {};

['webp', 'png'].forEach(el => {
    if (srcSet[el]) {
        srcStrings[el] = srcSet[el].join(', ');
    }
});

</script>

<template>
    <div v-if="imageSet && imageSet.length > 0" class="product-picture">
        <picture>
            <source v-for="key in Object.keys(srcStrings)" :srcset="srcStrings[key]"
                sizes="(max-width=1600px) 256px, (max-width=1400px) 241px, (max-width=1200px) 270px, (max-width=578px) 140px, 290px"
                :type="`image/${key}`">
            <img :src="imageUrl" :alt="name" :title="name">
        </picture>
    </div>
    <div v-else class="product-image ratio ratio-1x1" :style="{ 'background-image': `url(${imageUrl})` }">
    </div>
</template>

<style lang="scss" scoped>
.product-picture {
    image-rendering: auto;
    user-select: none;
    -webkit-user-select: none;
    width: 100%;

    img {
        width: 100%;
    }
}
</style>