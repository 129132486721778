<script setup>
const emit = defineEmits(['close']);
const modal = ref(null);

const close = () => {
    emit('close');
}

</script>

<template>
    <div style="position: relative; z-index: 1000;">
        <div class="d-modal" ref="modal">
            <div @click="close" class="d-modal-overlay"></div>
            <div class="d-modal-container">
                <div class="d-modal-content">
                    <slot />
                </div>
            </div>

        </div>
    </div>
</template>

<style lang="scss">
@media screen and (min-width: 760px) {
    .d-modal-product-slim {
        .d-modal {
            .d-modal-container {
                .d-modal-content {
                    max-width: 400px;
                }
            }
        }
    }
}

.product-modal {
    .d-modal {
        .d-modal-container {
            width: 100%;

            .d-modal-content {
                display: flex;
                flex-direction: column;
                max-width: 100%;

                width: 1100px;

                @media screen and (max-width: 1200px) {
                    // max-height: 550px;
                    width: 960px;
                }

                @media screen and (max-width: 1100px) {
                    // max-height: 550px;
                    width: 860px;
                }

                @media screen and (max-width: 900px) {
                    // max-height: 550px;
                    width: 760px;
                }

                @media screen and (max-width: 760px) {
                    // max-height: 550px;
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }
    }
}

.d-modal-product-slim {
    .product-modal {
        .d-modal {
            .d-modal-container {
                .d-modal-content {
                    width: 100%;
                    max-width: 400px;

                    @media screen and (max-width: 760px) {
                        width: 100%;

                        border-bottom-right-radius: 20px;
                        border-bottom-left-radius: 20px;
                    }
                }
            }
        }
    }
}

.d-modal {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;

    position: fixed;
    left: 0;
    top: 0;
    width: 100%;

    height: 100%;

    overflow-x: hidden;
    overflow-y: auto;



    .d-modal-anim {
        opacity: 0;
        // transform: translateY(20%);
        transition: all 0.5s;

        &.show {
            // transform: none;
            opacity: 1;
            transition: all 0.3s;
        }
    }

    .d-modal-overlay {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        opacity: 1;
        transition-duration: 150ms;
        transition-timing-function: ease;
        transition-delay: 0s;
        transition-property: opacity;
        will-change: opacity;
        background: $model-overlay;
        backdrop-filter: blur(3.5px);

        // &:hover {
        //     opacity: 0.35;
        // }
    }

    .d-modal-container {
        padding-top: 24px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        flex-direction: column;
        min-height: 100%;

        @media screen and (max-width: 760px) {
            padding-top: 0;
        }


        &::before,
        &::after {
            content: " ";
            display: block;
            width: 100%;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
        }

        .d-modal-content {
            position: relative;
            width: 100vw;
            max-width: 70%;
            margin-left: auto;
            margin-right: auto;

            overflow-x: hidden;
            overflow-y: hidden;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;

            @media screen and (max-width: 1300px) {
                max-width: 90%;
            }

            @media screen and (max-width: 760px) {
                max-width: 100%;
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
            }


            .d-modal-loader {
                text-align: center;
                margin: auto;
            }
        }
    }
}
</style>