<script setup>
import { useActiveOrdersStore } from '~/stores/activeOrders';
import { useAuthStore } from '~/stores/auth';

const authStore = useAuthStore();
const ordersStore = useActiveOrdersStore();

let interval = null;

const fetchOrders = () => {
    if (!authStore.user) {
        return;
    }

    ordersStore.fetch();
    setupInterval();
};

const setupInterval = () => {
    if (interval) {
        clearInterval(interval);
    }

    interval = setInterval(fetchOrders, 30000);
};

onBeforeUnmount(() => {
    if (interval) {
        clearInterval(interval);
    }
});

if (process.client) {
    ordersStore.fetch();
    setupInterval();
}

</script>
<template>
    <div class="container-lg">
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
            <div v-for="item in ordersStore.activeOrders" class="col">
                <DLink :to="`/orders/${item.id}`" class="active-order-card">
                    <div class="top-row">
                        <div class="name">Заказ №{{ item.number }}</div>
                        <div v-if="item.courier_eta" class="eta">~{{ item.courier_eta }} мин.</div>
                    </div>

                    <div class="statuses">
                        <div v-for="status in item.status_stages" class="status-stage">
                            <div v-if="status.progress >= 0 && status.progress < 1" class="progress" role="progressbar"
                                :aria-valuenow="status.progress * 100" aria-valuemin="0" aria-valuemax="100">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                    :style="{ width: `${status.progress * 100}%` }">
                                </div>
                            </div>
                            <div v-else class="progress" role="progressbar" :aria-valuenow="status.progress * 100"
                                aria-valuemin="0" aria-valuemax="100">
                                <div class="progress-bar" :style="{ width: `${status.progress * 100}%` }">
                                </div>
                            </div>
                            <div class="status-name">{{ status.name }}</div>
                        </div>
                    </div>

                    <div v-if="item.courier_eta" class="track">
                        Посмотреть на карте
                    </div>
                </DLink>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.active-order-card {
    display: block;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 1px 9px 30px #00000008;

    padding: 20px;
    padding-bottom: 8px;
    margin-top: 16px;

    text-decoration: none;
    cursor: pointer;

    @if not $is-domino {
        color: #000;
        border: 1px solid #000;
    }

    .top-row {
        display: flex;

        .name {
            font-size: 14px;
            font-weight: bold;
            width: 100%;
        }

        .eta {
            color: #000;
            font-size: 14px;
            text-align: right;
            width: 100%;
        }

    }

    .track {
        font-size: 12px;
        color: #000000a6;
        font-weight: 500;
        text-align: right;
    }

    .statuses {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-top: 10px;
        margin-bottom: 10px;

        .status-stage {
            width: 100%;
            flex: 1;
            overflow: hidden;

            .progress {
                width: 100%;
                height: 10px;

                @if not $is-domino {
                    border: 1px solid #000;
                }
            }

            .status-name {
                font-size: 10px;
                font-weight: 500;
                color: #797979;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }


    }
}
</style>