<script setup>
import { useAuthStore } from '~/stores/auth';

const nuxtApp = useNuxtApp();

const props = defineProps(['modalId']);
const store = useAuthStore();

const text = ref("Зарегистрироваться");

if (store.user != null) {
    text.value = null;
}

const onClick = () => {
    if (store.user == null) {
        nuxtApp.$modalsBus.$emit(`modals.promo-popup:close`);
        useRouter().push('/auth/login');
    }
};

const close = () => {

    nuxtApp.$modalsBus.$emit(`modals.promo-popup:close`);
}

</script>
<template>
    <DModal @close="close" class="promo-d-modal">
        <div class="promo-popup">
            <div @click="close" class="close-button"><i class="bi bi-x"></i></div>
            <h2>Всем новым гостям дарим 300 баллов на первый заказ!</h2>
            <p>Регистрируйтесь на сайте или в мобильном приложении Domиno Pizza, получайте 300 приветственных бонусов и
                совершайте заказ с выгодой.<br />
                1 балл = 1 рублю.
                <br /><br />
                Выбирайте пиццу, как вам нравится: на тонком или пышном тесте, с тягучим сыром, с начинками на свой вкус.
                <br /><br />
                Ждем в команде Domиno Pizza!
            </p>
            <div>
                <DButton @click="onClick" class="red text-center py-2" :disabled="text == null">
                    {{ text ? text : "Вы уже зарегистрированы" }}
                </DButton>
            </div>
        </div>
    </DModal>
</template>

<style lang="scss">
.promo-d-modal {
    .d-modal {
        .d-modal-container {

            .d-modal-content {
                max-width: 700px;
            }
        }

    }
}

.promo-popup {
    background: linear-gradient(94deg, #0074AE -1.72%, #2A9FCE 105.42%);

    padding: 30px 20px;

    h2 {
        font-weight: 700;
        font-size: 28px;
        margin-bottom: 20px;
        text-transform: uppercase;
        color: #fff;
    }

    p {
        font-weight: 500;
        line-height: 1;
        font-size: 20px;
        margin-bottom: 40px;
        color: #fff;
    }

    .close-button {
        position: absolute;
        right: 4px;
        top: 4px;

        width: 40px;
        height: 40px;

        padding: 4px;
        font-size: 30px;
        line-height: 0;
        text-align: center;
        color: #fff;
        cursor: pointer;
        z-index: 100;

        i {
            display: block;
            position: relative;
            top: 50%;
            transform-origin: center;
            transform: scale(1) translate(0, -50%);
        }
    }
}
</style>